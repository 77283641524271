<template>
  <!--
    The view for the CutConfig-component
  -->
  <Portlet
    title="Image Collection Elements"
    icon="images"
  >
    <template slot="buttons">
      <button 
        class="btn btn-sm btn-primary float-right mt-2 mb-2" 
        @click="showSidebarAdd"
      >
        <font-awesome-icon
          class="mr-1"
          icon="plus"
        />
        <span>
          {{ $t('add') }}
        </span>
      </button>
      <div class="clearfix" />
    </template>
    <CollectionElementsList
      ref="collectionElementsList"
      :collection-id="collectionId"
      @reloadAuditLogs="reloadAuditLogs"
    />
    <DatabaseAuditLogList
      ref="databaseAuditLogList"
      :collection-id="collectionId"
      @reload="reloadCollectionElementList"
    />
  </Portlet>
</template>

<script>
export default {
  name: "Collections",
  components: {
    CollectionElementsList: () => import('@/components/VideoPostprocessing/ImageCollections/CollectionElementsList.vue'),
    DatabaseAuditLogList: () => import('@/components/DatabaseAuditLogs/DatabaseAuditLogsList.vue')
  },
  props: {
    collectionId: {
      type: String,
      required: true
    }
  },
  methods: {
    showSidebarAdd () {
      this.$refs.collectionElementsList.showSidebarAdd();
    },
    reloadAuditLogs () {
      this.$refs.databaseAuditLogList.reloadAuditLogs();
    },
    reloadCollectionElementList () {
      this.$refs.collectionElementsList.reloadCollectionElements();
    }
  }
}
</script>